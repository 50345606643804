/** @jsxImportSource @emotion/react */
import React from 'react';
import { useZxing } from 'react-zxing';
import { useTranslation } from 'react-i18next';
import style from './barcodeScanner.style';

export const BarcodeScanner = ({
  onBarcodeDetection,
}: {
  onBarcodeDetection: (barcode: string) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const { ref } = useZxing({
    onResult(r) {
      const barcode = r.getText();
      const isValidBarcodeCode = /^\d+$/.test(barcode);
      if (isValidBarcodeCode) {
        onBarcodeDetection(barcode);
      }
    },
    timeBetweenDecodingAttempts: 2000,
  });

  return (
    <div css={style.cameraScan}>
      <div css={style.cameraContainer}>
        <div css={style.targetSquareMessage}>{t('codeScanner.scanBarcode')}</div>
        <video css={style.video} ref={ref} muted />
        <BarcodeSquare />
      </div>
    </div>
  );
};

const BarcodeSquare = (): JSX.Element => {
  return (
    <div css={style.barcodeSquareContainer}>
      <div css={style.targetSquare}>
        <div css={style.corners} />
      </div>
    </div>
  );
};
