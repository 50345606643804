/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dimmer, Icon, Label, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  resetProductSearch,
  searchProductByBarcode,
  selectIsFetchingByBarcode,
} from '../store/productSearchSlice';
import Header from '../../../../shared/components/Header';
import { selectEmployeeCard } from '../../cardSearch/store/employeeDiscountCardSlice';
import { ProductsDropdown } from '../components/ProductsDropdown';
import { selectSelectedStore } from '../../cardSearch/store/salesAssistantSlice';
import style from './productSearch.style';
import { isGucciBrandCode } from '../../../../shared/models/brand';
import { useBarcodeScannerDevice } from '../utils/useBarcodeScannerDevice';
import { Product, emptyProduct } from '../models/product';
import { SalesAssistantStore } from '../../../auth/models/principal';
import { BarcodeScanner } from '../components/BarcodeScanner';
import { isGucciStoreException } from '../../../../shared/utils/utils';
import { ProductDetail } from './ProductDetail';
import { fetchImage } from '../store/productImageSlice';
import { ProductsBasket } from './ProductsBasket';
import { selectBasketSize } from '../store/basketSlice';
import { EmployeeCard } from '../../../../shared/models/employeeCard';
import { toastService } from '../../../../core/services/toastService';
import { useProductSelection } from '../utils/useProductSelection';

export default function ProductSearch(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const salesAssistantStore = useAppSelector(selectSelectedStore) as SalesAssistantStore;
  const employeeCard = useAppSelector(selectEmployeeCard);
  const basketEnabled = isGucciStoreException(salesAssistantStore?.storeCode);
  const [isBasketActive, setIsBasketActive] = useState(false);
  const [isProductSelected, setIsProductSelected] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product>(emptyProduct())
  const [currentProductsList, setCurrentProductsList ] = useState<Product[]>([])

  useEffect(() => {
    dispatch(resetProductSearch());
    setIsProductSelected(false);
    setSelectedProduct(emptyProduct())
  }, [dispatch, salesAssistantStore?.brand.code]);

  return (
    <div css={style.generalContainer}>
      {employeeCard.mail === '' && (
        <Dimmer active>
          {t('salesAssistant.productsSearch.pleaseSelectCard')} {t('salesAssistant.productsSearch.click')}{' '}
          <Link to='/privilege-card/sales-assistant/card-search'>{t('salesAssistant.productsSearch.here')}</Link>{' '}
          {t('salesAssistant.productsSearch.toRedirect')}
        </Dimmer>
      )}
      <div css={style.headerContainer}>
        <Header
          title={
            basketEnabled && isGucciBrandCode(salesAssistantStore?.brand.code)
              ? t('salesAssistant.productsSearch.scanProducts')
              : t('salesAssistant.productsSearch.searchProducts')
          }
          redirectPath='/privilege-card/sales-assistant/card-search'
        />
      </div>
      <div css={style.container}>
        {basketEnabled && isGucciBrandCode(salesAssistantStore?.brand.code) ? (
          <ProductsDropdownWithBasket
            store={salesAssistantStore}
            isBasketActive={isBasketActive}
            setIsBasketActive={setIsBasketActive}
            employeeCard={employeeCard}
            isProductSelected={isProductSelected}
            setIsProductSelected={setIsProductSelected}
            selectedProduct={selectedProduct}
            setSelectedProduct={(setSelectedProduct)}
            currentProductsList={currentProductsList}
            setCurrentProductsList={setCurrentProductsList}
          />
        ) : (
          <ProductDropdownSearchBox
            store={salesAssistantStore}
            isProductSelected={isProductSelected}
            setIsProductSelected={setIsProductSelected}
            setSelectedProduct={setSelectedProduct}
          />
        )}
      </div>
      {isProductSelected && <ProductDetail selectedProduct={selectedProduct} setIsProductSelected={setIsProductSelected} />}
    </div>
  );
}

const ProductDropdownSearchBox = ({
  store,
  isProductSelected,
  setIsProductSelected,
  setSelectedProduct,
}: {
  store: SalesAssistantStore;
  isProductSelected: boolean;
  setIsProductSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProduct: React.Dispatch<React.SetStateAction<Product>>;
}) => {
  const dispatch = useAppDispatch()
  
  return (
    <div css={isProductSelected ? style.topSearchBox : style.centeredSearchBox}>
      <ProductsDropdown
        store={store}
        onSelection={product => {
          setIsProductSelected(true);
          setSelectedProduct(product);
          dispatch(fetchImage(product.image))
        }}
      />
    </div>
  );
};

const ProductsDropdownWithBasket = ({
  store,
  isBasketActive,
  setIsBasketActive,
  employeeCard,
  isProductSelected,
  setIsProductSelected,
  selectedProduct,
  setSelectedProduct,
  currentProductsList,
  setCurrentProductsList
}: {
  store: SalesAssistantStore;
  isBasketActive: boolean;
  setIsBasketActive: React.Dispatch<React.SetStateAction<boolean>>;
  employeeCard: EmployeeCard;
  isProductSelected: boolean;
  setIsProductSelected: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProduct: Product;
  setSelectedProduct: React.Dispatch<React.SetStateAction<Product>>;
  currentProductsList: Product[];
  setCurrentProductsList: React.Dispatch<React.SetStateAction<Product[]>>;
}) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch();
  const basketSize = useAppSelector(selectBasketSize);
  const isFetchingByBarcode = useAppSelector(selectIsFetchingByBarcode);
  const { deviceBarcode } = useBarcodeScannerDevice();
  
  const getNonEligibleItemWarning = useCallback((sku: string): string => {
    return `${t('salesAssistant.productsSearch.item')} ${sku} ${t('salesAssistant.productsSearch.notEligible')}`
  }, [t])

  const getProductAlreadyChosenWarning = useCallback((sku: string): string => {
    return `${t('salesAssistant.productsSearch.item')} ${sku} ${t('salesAssistant.productsSearch.alreadyPresent')}`
  }, [t])

  const onProductSelection = useCallback((product: Product) => {
    if(!product.isEligible) {
      toastService.error(getNonEligibleItemWarning(product.sku))
      return
    }

    const alreadyChosen = product.sku !== ''  && currentProductsList.some((p) => p.sku === product.sku)
    if(alreadyChosen) {
      toastService.error(getProductAlreadyChosenWarning(product.sku))
      return
    }
    if (!alreadyChosen) {
      setCurrentProductsList([...currentProductsList, product]);
    }

    setSelectedProduct(product);
    setIsBasketActive(true)
    setTimeout(() => {
      toastService.success()
    }, 1000)
  }, [getNonEligibleItemWarning, currentProductsList, getProductAlreadyChosenWarning, setCurrentProductsList, setSelectedProduct, setIsBasketActive]);

  useProductSelection(employeeCard, store, selectedProduct);


  const handleSearchByBarcode = useCallback(
    async (barcode: string) => {
      const product = await dispatch(searchProductByBarcode(store.storeCode, barcode));
      if (product != null) {
        onProductSelection(product);
      }
    },
    [dispatch, onProductSelection, store.storeCode]
  );
  

  useEffect(() => {
    setIsBasketActive(isBasketActive)
    if (deviceBarcode !== '') {
      handleSearchByBarcode(deviceBarcode);
    }
  }, [setIsBasketActive, isBasketActive, deviceBarcode, handleSearchByBarcode]);

  const backToScanner = () => {
    setIsBasketActive(false);
    dispatch(resetProductSearch());
  };

  return (
    <div css={style.productScanner}>
      {isFetchingByBarcode && (
        <Dimmer active inverted>
          <Loader size='big' />
        </Dimmer>
      )}
      <div css={style.productScannerDropdownContainer}>
        <ProductsDropdown store={store} onSelection={onProductSelection} />
        {isBasketActive && <BarcodeButton backToScanner={backToScanner} />}
        <BasketButton
          basketSize={basketSize}
          onClick={() => {
            setIsBasketActive(true)
            setIsProductSelected(false)
          }}
        />
      </div>
        {!isProductSelected && (
          isBasketActive ? (
            <ProductsBasket 
            setIsProductSelected={setIsProductSelected} 
            setSelectedProduct={setSelectedProduct} 
            currentProductsList={currentProductsList} 
            setCurrentProductsList={setCurrentProductsList} 
            />
          ) : (
            <BarcodeScanner
              onBarcodeDetection={barcode => {
                handleSearchByBarcode(barcode);
              }}
            />
          )
        )}
    </div>
  );
};

const BasketButton = ({ basketSize, onClick }: { basketSize: number; onClick: () => void }) => {
  return (
    <div css={style.basketButtonContainer}>
      <Button icon css={style.basketButton} onClick={onClick}>
        <Icon name='shop' size='large' color='black' />
        <Label as='a' floating css={style.basketButtonCounter}>
          {basketSize}
        </Label>
      </Button>
    </div>
  );
};

const BarcodeButton = ({ backToScanner }: { backToScanner: () => void }) => {
  const { t } = useTranslation();
  return (
    <Button css={style.scanBarcodeButton} onClick={backToScanner}>
      {t('codeScanner.scanBarcode')}
      <div css={style.corners} />
    </Button>
  );
};

