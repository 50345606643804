import axios from 'axios';
import paths from './paths';
import { Product, ProductsRequest, toProduct } from '../models/product';
import { ProductCategory } from '../../../../shared/models/productCategory';
import { Basket, BasketUpdateRequest, toBasket } from '../models/basket';

const api = {
  getProduct: async (storeCode: string, sku: string): Promise<Product> => {
    const response = await axios.get(paths.getProduct(storeCode, sku));
    return toProduct(response.data);
  },
  getProducts: async (storeCode: string, filter: string, request: ProductsRequest): Promise<Product[]> => {
    const response = await axios.post(paths.getProducts(storeCode, filter), request);
    return response.data.map(toProduct);
  },
  getProductCategories: async (storeCode: string): Promise<ProductCategory[]> => {
    const response = await axios.get(paths.getProductCategories(storeCode));
    return response.data;
  },
  updateBasket: async (request: BasketUpdateRequest): Promise<Basket> => {
    const response = await axios.put(paths.updateBasket(), request);
    return toBasket(response.data);
  },
  getProductImage: async (imageUrl: string): Promise<string> => {
    const response = await axios.get(paths.getProductImage(imageUrl))
    return response.data
  }
};

export default api;
